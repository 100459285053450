import React, { Component } from "react"
import { Link } from "gatsby"
import "../assets/css/success.css"
import closeChat from "../images/close-icon.svg"
import booklense from "../images/booklense.svg"
import bigstack from "../images/home-big-stack.svg"
import halfstack from "../images/home-half-stack.svg"
import stacks from "../images/newsec/stacks.png"
class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false
        };
    }

    render() {
        const { showPopup } = this.state;
        return (
            <section className="payClaims-container">
                <div className="container">
                <h3 className="title">How servicecontract.com plans are different</h3>
                </div>
                <div className="container">
                    <div className="left t-center">
                    <img src={stacks} alt="Stacks Logo" className="stacks" />
                    </div>
                    <div className="right">
                    <div className="payClaims-content">
                        
                        <p>Styled as a monthly subscription plan instead of a long-term contract, just like a streaming channel or fitness membership, servicecontract.com coverage options are very affordable and are easy to change or discontinue as needed.
                            <br />Our VSC subscriptions are also packed with extra perks. These include emergency roadside assistance, rental car coverage, and the Stacks Reward Plan, a unique plan included with the monthly contract that makes a VSC purchase virtually risk-free.
                            <sup className="small-1">#2</sup>
                            <Link className="btn stactn" to="faq/#stack" > More Info on Stacks</Link>
                        </p>
                        

                        <p>Our Lease Care Ultimate VSC features a wide range of coverages and a money-saving benefit that is so valuable it can literally pay for itself!
                        <Link className="btn stactn" to="protect/#lease" > More Info on Lease Care</Link>
                        </p>

                        <p>As an added benefit, your subscription includes complimentary family coverage ID Theft Protection. This comprehensive suite of high-value protective services and benefits includes best-in-class fraud prevention and remediation services paired with 24/7 customer service.
                        <Link className="btn stactn" to="protect/#tirewheel" > More Info on ID Theft</Link>
                        </p>    
                    </div>
                    </div>
                </div>
                {/* <div className="container">
                    <div className="payClaims-content">
                    
                    </div>
                </div> */}
                {showPopup ?
                        <div className="success-container stackspp">
                        <div className="success-inner large">
                            <div onClick={() => this.setState({ showPopup: false })} className="close-btn close">
                                <img className="" src={closeChat} alt="" />
                            </div>
                            <div className="content">
                            <h2 className="head">STACKS REWARD PLAN</h2>
                            <div className="subHead">The Stacks Reward Plan is designed to put Stacks of cash back into your pockets!</div>
        
                            {/* <div className="sPdesc">
                                <div className="booklense"><img src={booklense} alt="" /></div>
                                <div className="booklenseText">OK, here’s the small print on how this works (it’s not even that small!) </div>
                            </div> */}
        
                            <div className="stackspp-box">
                                {/* <div className="stackImg">
                                Half Stacks
                                <img className="" src={halfstack} alt="" />
                                </div> */}
                                <div className="stacksText">
                                    <br />
                                    <span className="subtit">Here’s how it works: </span> 
                                    <div className="stacp">With your purchase of a monthly Vehicle Service Contract or Lease Care Ultimate Service Contract, you are automatically enrolled in the Stacks Reward Plan. </div>
                                    <div className="stacp">If you have no need to make a claim during the term of your service contract, you will be eligible for reimbursement of your total monthly service contract payments, minus certain fees.</div>
                                    <div className="stacp">In short, you pay a monthly fee for valuable VSC coverage, then get the unused portion of your service contract payments 
                                    back at the end of the participation term, less certain fees.<Link to="/legalstuff/#stacks"> Terms and conditions </Link>apply. </div>
                                    
                                    <br />
                                </div>
                            </div>
        
        
                           
                            <div className="wow">
                                <div>Simple and rewarding!</div>
                                <Link to="/vehicleInfo/" className="btn primary get-price">Get your Quote</Link>
                            </div>
                            </div>
                        </div>
                    </div>
                :''}
            </section>

           
            
        );
    }
}



export default Popup;
