import './header.css';
//import { Link } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { navigate } from "gatsby"

import mobileImg from '../images/mobile-img.png';
//import mapIcon from '../images/map.svg';
import Popup from "../components/popup";
import { zonar } from './../components/constant'
import MyAuto from "../components/MyAuto";
//import popup from './popup';

class ShoppingContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      zone:'',
      howPopup: false
    };
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  submit = () => {
    navigate("/vehicleInfo", {
      state: { zone: this.state.zone},
    })
  }
  sugChange = async(value, type) => {
    this.setState({ zone:value,canSubmit: zonar.includes(value) });
  }
 
  render() {
    const { canSubmit } = this.state;
    
    return (
      
        
      
      <section className={`shoping-container ${this.props.className && this.props.className}`}>
        
        
        
        <div className="container">
          <div className="shoping-content">
            <div className="left">
              <h3 className="shoping-title">{/* We offer coverages on almost EVERYTHING on your vehicle.  */}Shopping is simple from <br /> your phone!</h3>
              <p className="subtitle">We put all available coverages in one place.</p>
              <div className="m-shoping-part-img">
                <img src={mobileImg} alt="Logo" />
              </div>
              <Formsy ref="shoppingContainerForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                <div className="input-container">
                  {/* <img className="map-img" src={mapIcon} alt="map" /> */}

                    {/* <MySelect
                        name="zone"
                        value={this.state.zone}
                        selecteChange={(e) => this.selectionChange(e, 'zone')}
                        options={zonar}
                        className="form-control"
                      /> */}
                      <MyAuto 
                        value={this.state.zone} 
                        options={zonar}  
                        sugChange={(e) => this.sugChange(e, 'zone')}
                        name="zone"
                        placeholder="Select Your State"
                      />
                  <br />
                  <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'disabled btn primary' : 'btn primary'}>
                    GET STARTED
                  </button>
                  <div>
                    <b>Best Price Guarantee</b>
                    {/* <span className="howpopup" onClick={() => this.setState({ howPopup: true})}> How does it work?</span> */}
                    <Popup
                      linkText={'How does it work?'}
                      popupHead={'How does it work?'} 
                      popupMessage={`This is easy!  If you find a better price while searching for your service contract online, simply provide us the other price quote and we will beat it!  No explanations, no caveats…just a solid bargain for you!  Questions??  Either hit the chat button at the bottom or email us at <a href="mailto:help@servicecontract.com" >help@servicecontract.com</a>`}  
                      btnLabel={'Contact Us'} 
                    />
                    
                    
                  </div>
                </div>
              </Formsy>

            </div>
            <div className="shoping-part-img">
              <img src={mobileImg} alt="Logo" />
            </div>
          </div>
        </div>
        
      </section>

     
      
      
    )

  }

}


const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingContainer);

